import React from "react";

import { type TestimonialsData } from "../../../types/games";

interface TestimonialsProps {
  maxColumns?: 2 | 4;
  data: TestimonialsData;
}

const Testimonials: React.FC<TestimonialsProps> = ({
  maxColumns = 4,
  data,
}) => {
  const wrapperClass =
    maxColumns === 4 ? "sm:grid-cols-2 md:grid-cols-4" : "sm:grid-cols-2";

  if (!data || !data.length) return null;

  return (
    <div className={`grid grid-cols-1 gap-4 ${wrapperClass}`}>
      {data?.map((item) => (
        <div
          key={item.id}
          className="py-5 px-2 rounded-lg bg-brand/[0.08] text-center"
        >
          {item.avatar && (
            <img
              src={item.avatar}
              className="w-[68px] h-[68px] rounded-full mx-auto mb-3"
            />
          )}
          <div className="grid gap-2 text-brand font-sans ">
            {item.topText && (
              <div className="font-medium text-xs">{item.topText}</div>
            )}
            {item.middleText && (
              <div className="font-bold text-2xl">{item.middleText}</div>
            )}
            {item.bottomText && (
              <div className="text-xs font-medium tracking-tight">
                {item.bottomText}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Testimonials;
