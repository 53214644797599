import { useUser } from "@clerk/clerk-react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import Layout from "./components/layout";
import Spinner from "./components/spinner";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isLoaded, isSignedIn } = useUser();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");

  if (!isLoaded) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner height="16" />
      </div>
    );
  }

  if (!isSignedIn) {
    // Include both pathname and search params in the redirect
    const fullPath = location.pathname + location.search;
    return (
      <Navigate to={`/?redirect_url=${encodeURIComponent(fullPath)}`} replace />
    );
  }

  // If no team is selected, redirect to landing page with the current URL as redirect
  if (!teamId) {
    // Include both pathname and search params in the redirect
    const fullPath = location.pathname + location.search;
    return (
      <Navigate to={`/?redirect_url=${encodeURIComponent(fullPath)}`} replace />
    );
  }

  return <Layout>{children}</Layout>;
}

export default ProtectedRoute;
