import React from "react";
import { TestimonialVertical } from "../../../types/games";

export interface TestimonialsVerticalProps {
  data: TestimonialVertical[];
}

export const TestimonialsVertical: React.FC<TestimonialsVerticalProps> = ({
  data,
}) => {
  return (
    <div className="divide-y">
      {data.map((item) => (
        <div
          key={item.id}
          className="py-3 flex items-center justify-between gap-4"
        >
          <div className="flex items-center gap-3">
            {item.avatar && (
              <img src={item.avatar} alt="" className="w-8 h-8 rounded-full" />
            )}
            <span className="text-sm font-medium">{item.text}</span>
          </div>
          <span className="text-sm text-brand font-semibold">{item.value}</span>
        </div>
      ))}
    </div>
  );
};
