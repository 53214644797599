import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";

export interface RadarChartItem {
  name: string;
  value: number;
  format?: "number" | "percent";
  isDemo?: boolean;
}

export interface RadarChartProps {
  data: RadarChartItem[];
}

export const RadarChartComponent: React.FC<RadarChartProps> = ({ data }) => {
  if (!data || !data.length) return null;

  const demoData: RadarChartItem[] = [
    { name: "science", value: 65, isDemo: true },
    { name: "history", value: 70, isDemo: true },
    { name: "geography", value: 55, isDemo: true },
    { name: "entertainment", value: 75, isDemo: true },
  ];

  const displayData = data.length < 3 ? [...data, ...demoData] : data;
  const sortedData = [...displayData].sort((a, b) => b.value - a.value);

  return (
    <div className="relative">
      <ResponsiveContainer width="100%" height={400}>
        <RadarChart outerRadius="80%" data={sortedData}>
          <PolarGrid stroke="#E5E7EB" />
          <PolarAngleAxis
            dataKey="name"
            tick={(props) => (
              <text
                x={props.x}
                y={props.y}
                textAnchor={props.textAnchor}
                fill="#6B7280"
                fontSize={12}
                fontWeight={400}
                fontFamily="Inter"
                opacity={sortedData[props.index].isDemo ? 0.3 : 1}
              >
                {props.payload.value}
              </text>
            )}
          />
          <Radar
            name="Accuracy"
            dataKey="value"
            stroke="#4641EB"
            strokeOpacity={0.8}
            fill="#7367FF"
            fillOpacity={0.2}
          />
          <PolarRadiusAxis
            angle={90}
            domain={[0, 100]}
            tick={({ x, y, payload }) => (
              <g>
                <rect
                  x={x - 15}
                  y={y - 8}
                  width={30}
                  height={16}
                  fill="white"
                  rx={2}
                />
                <text
                  x={x}
                  y={y}
                  dy={4}
                  textAnchor="middle"
                  fill="#6B7280"
                  fontSize={10}
                >
                  {`${payload.value}%`}
                </text>
              </g>
            )}
            tickCount={5}
          />
        </RadarChart>
      </ResponsiveContainer>
      {data.length < 3 && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/80">
          <div className="text-center p-4 rounded-lg">
            <p className="text-sm text-gray-600">
              Try more categories to see your full mastery chart!
            </p>
            <p className="text-xs text-gray-500 mt-1">
              ({data.length}/3 categories completed)
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
