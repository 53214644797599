import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  CartesianGrid,
} from "recharts";

export interface ComparisonDataItem {
  name: string;
  leftValue: number;
  rightValue: number;
}

export interface ComparativeBarChartProps {
  data: ComparisonDataItem[];
  leftColor?: string;
  rightColor?: string;
  leftName?: string;
  rightName?: string;
}

const ROW_HEIGHT = 68;
const DEFAULT_LEFT_COLOR = "#4641EB";
const DEFAULT_RIGHT_COLOR = "#b9b9b9";

interface CustomYAxisTickProps {
  x: number;
  y: number;
  payload: {
    value: string;
  };
}

const CustomYAxisTick = ({ x, y, payload }: CustomYAxisTickProps) => (
  <text
    x={x}
    y={y}
    dy={0}
    dx={-8}
    textAnchor="end"
    className="fill-gray-900 text-xs font-medium"
    dominantBaseline="middle"
  >
    {payload.value}
  </text>
);

export const ComparativeBarChart: React.FC<ComparativeBarChartProps> = ({
  data = [],
  leftColor = DEFAULT_LEFT_COLOR,
  rightColor = DEFAULT_RIGHT_COLOR,
  leftName = "You",
  rightName = "Them",
}) => {
  if (!data?.length) return null;

  const height = data.length * ROW_HEIGHT;

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 16, right: 24, bottom: 8, left: 0 }}
        barGap={8}
      >
        <CartesianGrid horizontal={false} stroke="#f0f0f0" />
        <XAxis
          type="number"
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => `${value}%`}
          fontSize={12}
          tick={{ fill: "#666" }}
        />
        <YAxis
          type="category"
          dataKey="name"
          tickLine={false}
          axisLine={false}
          width={130}
          tick={CustomYAxisTick}
        />
        <Legend
          verticalAlign="top"
          align="right"
          height={36}
          iconType="circle"
          iconSize={8}
        />
        <Bar
          dataKey="leftValue"
          fill={leftColor}
          name={leftName}
          radius={[4, 4, 4, 4]}
          maxBarSize={24}
        />
        <Bar
          dataKey="rightValue"
          fill={rightColor}
          name={rightName}
          radius={[4, 4, 4, 4]}
          maxBarSize={24}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
