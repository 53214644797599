import React from "react";
import {
  WidgetCard,
  HorizontalBar,
  SquaresGrid,
  TagList,
  Testimonials,
  GradientLineChart,
  KeyValueList,
} from "./insights";

import {
  ComparisonTagList,
  ComparisonOption,
  ComparisonTagListProps,
} from "./insights/ComparisonTagList";

import {
  RadarChartComponent,
  RadarChartProps,
  RadarChartItem,
} from "./insights/RadarChart";

import {
  ComparativeBarChart,
  ComparativeBarChartProps,
  ComparisonDataItem,
} from "./insights/ComparativeBarChart";

import {
  TestimonialsVertical,
  TestimonialsVerticalProps,
} from "./insights/TestimonialsVertical";

import {
  THIS_OR_THAT,
  WORDSMITH,
  PICTURE_PUZZLE,
  TYPING_TEST,
  WORD_LINK,
  TRIVIA,
} from "../constants";

import {
  GameName,
  GameType,
  Testimonial,
  Square,
  Tag,
  HorizontalBarItem,
  GradientLineChartData,
  KeyValueItem,
  GameChannelInsightsMap,
  GameInsightsMap,
  GameDirectInsightsMap,
  TestimonialVertical,
  GradientLineChartDataComparison,
} from "../types/games";

import { ComparisonType } from "../types/insights";
import {
  GradientLineChartComparison,
  GradientLineChartComparisonProps,
} from "./insights/GradientLineChartComparison";

interface TestimonialsProps {
  data: Testimonial[];
}

interface SquaresGridProps {
  data: Square[];
  size?: "small" | "large";
  maxColumns?: number;
}

interface HorizontalBarProps {
  data: HorizontalBarItem[];
}

interface TagListProps {
  data: Tag[];
}

interface KeyValueListProps {
  data: KeyValueItem[];
}

interface GradientLineChartProps {
  data: GradientLineChartData;
}

type InsightComponents = {
  Testimonials: {
    component: typeof Testimonials;
    props: Omit<TestimonialsProps, "data">;
    dataType: Testimonial[];
  };
  SquaresGrid: {
    component: typeof SquaresGrid;
    props: Omit<SquaresGridProps, "data">;
    dataType: Square[];
  };
  HorizontalBar: {
    component: typeof HorizontalBar;
    props: Omit<HorizontalBarProps, "data">;
    dataType: HorizontalBarItem[];
  };
  TagList: {
    component: typeof TagList;
    props: Omit<TagListProps, "data">;
    dataType: Tag[];
  };
  KeyValueList: {
    component: typeof KeyValueList;
    props: Omit<KeyValueListProps, "data">;
    dataType: KeyValueItem[];
  };
  GradientLineChart: {
    component: typeof GradientLineChart;
    props: Omit<GradientLineChartProps, "data">;
    dataType: GradientLineChartData;
  };
  RadarChart: {
    component: typeof RadarChartComponent;
    props: Omit<RadarChartProps, "data">;
    dataType: RadarChartItem[];
  };
  ComparisonTagList: {
    component: typeof ComparisonTagList;
    props: Omit<ComparisonTagListProps, "data">;
    dataType: [ComparisonOption, ComparisonOption][];
  };
  TestimonialsVertical: {
    component: typeof TestimonialsVertical;
    props: Omit<TestimonialsVerticalProps, "data">;
    dataType: TestimonialVertical[];
  };
  GradientLineChartComparison: {
    component: typeof GradientLineChartComparison;
    props: Omit<GradientLineChartComparisonProps, "data">;
    dataType: GradientLineChartDataComparison;
  };
  ComparativeBarChart: {
    component: typeof ComparativeBarChart;
    props: Omit<ComparativeBarChartProps, "data">;
    dataType: ComparisonDataItem[];
  };
};

interface InsightConfig {
  id: keyof GameInsightsMap[keyof GameInsightsMap] | string;
  title: string;
  subtitle: string;
  halfWidth?: boolean;
  children: {
    component: InsightComponents[keyof InsightComponents]["component"];
    props: Partial<InsightComponents[keyof InsightComponents]["props"]>;
  }[];
}

type GameInsightConfigs = {
  [K in GameName]: {
    workspace: InsightConfig[];
    channel: InsightConfig[];
    direct: InsightConfig[];
  };
};

interface GameInsightsResponse {
  game_type: GameName;
  insights?:
    | GameInsightsMap[GameName]
    | GameChannelInsightsMap[GameName]
    | GameDirectInsightsMap[GameName];
}

interface GameInsightProps {
  data: GameInsightsResponse;
  game: GameType;
  comparisonType?: ComparisonType;
}

const gameInsightConfigs: GameInsightConfigs = {
  [THIS_OR_THAT]: {
    workspace: [
      {
        id: "data",
        title: "Birds of a Feather",
        subtitle:
          "Meet your opinion twins - the teammates who think just like you",
        halfWidth: false,
        children: [{ component: Testimonials, props: {} }],
      },
    ],
    channel: [
      {
        id: "game_counts",
        title: "Channel Champions",
        subtitle: "See who's running the show in this channel",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "channel_agreements",
        title: "Meeting of Minds",
        subtitle: "When the whole channel agrees (rare but beautiful)",
        halfWidth: true,
        children: [{ component: ComparisonTagList, props: {} }],
      },
      {
        id: "most_similar",
        title: "Your Channel Soulmates",
        subtitle: "The teammates who share your worldview",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "least_similar",
        title: "Opposite Day",
        subtitle: "Your polar opposites in the channel",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
    ],
    direct: [
      {
        id: "games_played",
        title: "The Track Record",
        subtitle: "Your shared decision-making journey",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "agreements",
        title: "Mind Meld Moments",
        subtitle: "When you two were perfectly in sync",
        halfWidth: true,
        children: [{ component: ComparisonTagList, props: {} }],
      },
    ],
  },

  [WORD_LINK]: {
    workspace: [
      {
        id: "word_link_num_unique_words",
        title: "Word Wizard Status",
        subtitle: "Your vocabulary prowess in numbers",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: { size: "small" } }],
      },
      {
        id: "word_link_performance",
        title: "Lexicon Leaderboard",
        subtitle: "How your word bank stacks up",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "word_link_common_words",
        title: "Your Greatest Hits",
        subtitle: "The words you can't resist using",
        halfWidth: true,
        children: [{ component: TagList, props: {} }],
      },
      {
        id: "word_link_strategies",
        title: "Winning Moves",
        subtitle: "Your signature word-finding tactics",
        halfWidth: true,
        children: [{ component: KeyValueList, props: {} }],
      },
    ],
    channel: [
      {
        id: "game_counts",
        title: "Word Battle Records",
        subtitle: "See how you stack up against channel champions",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "overall_performance",
        title: "Channel Word Mastery",
        subtitle: "Your word wizardry in this space",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: { size: "small" } }],
      },
      {
        id: "top_players_in_channel",
        title: "Vocabulary Virtuosos",
        subtitle: "The channel's word champions",
        halfWidth: true,
        children: [{ component: Testimonials, props: {} }],
      },
      {
        id: "most_frequent_words",
        title: "Channel Classics",
        subtitle: "The words that define this channel",
        halfWidth: false,
        children: [{ component: TagList, props: {} }],
      },
      {
        id: "your_rank_in_channel",
        title: "Power Ranking",
        subtitle: "Your place in the word pantheon",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: { size: "large" } }],
      },
    ],
    direct: [
      {
        id: "games_played",
        title: "Vocabulary Face-off",
        subtitle: "Your word duels in numbers",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: { size: "large" } }],
      },
      {
        id: "number_unique_words_played",
        title: "Word Arsenal",
        subtitle: "Comparing your vocabulary firepower",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: { size: "small" } }],
      },
      {
        id: "average_number_words_per_game",
        title: "Word Speed",
        subtitle: "Who's the quickest wordsmith?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "average_score_per_word",
        title: "Word Value",
        subtitle: "Who picks the punchier words?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "your_longest_words",
        title: "Your Epic Words",
        subtitle: "Your most impressive vocabulary flexes",
        halfWidth: true,
        children: [{ component: TagList, props: {} }],
      },
      {
        id: "other_longest_words",
        title: "Their Epic Words",
        subtitle: "Their most impressive vocabulary flexes",
        halfWidth: true,
        children: [{ component: TagList, props: {} }],
      },
    ],
  },

  [WORDSMITH]: {
    workspace: [
      {
        id: "wordsmith_level_distribution",
        title: "Level Mastery",
        subtitle: "Your journey through Smith levels",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "wordsmith_performance",
        title: "Word Artistry",
        subtitle: "Your wordsmith craftsmanship by the numbers",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: { size: "large" } }],
      },
      {
        id: "wordsmith_longest_words",
        title: "Legendary Finds",
        subtitle: "Your most impressive word discoveries",
        halfWidth: false,
        children: [{ component: TagList, props: {} }],
      },
    ],
    channel: [
      {
        id: "game_counts",
        title: "Word Wars",
        subtitle: "Battle stats from this channel",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "overall_performance",
        title: "Channel Mastery",
        subtitle: "Your wordsmith reputation here",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "average_number_words_per_minute",
        title: "Speed Smithing",
        subtitle: "Who crafts words the fastest?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "pangrams_found",
        title: "Perfect Words",
        subtitle: "Masters of the full alphabet",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "longest_words_played",
        title: "Word Legends",
        subtitle: "The most epic finds in this channel",
        halfWidth: false,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "top_players_in_channel",
        title: "Word Warriors",
        subtitle: "Champions of vocabulary",
        halfWidth: true,
        children: [{ component: Testimonials, props: {} }],
      },
      {
        id: "most_pangrams",
        title: "Pangram Pioneers",
        subtitle: "Kings and queens of complete words",
        halfWidth: true,
        children: [{ component: TestimonialsVertical, props: {} }],
      },
      {
        id: "most_points_per_minute",
        title: "Speed Demons",
        subtitle: "The fastest wordsmiths alive",
        halfWidth: true,
        children: [{ component: TestimonialsVertical, props: {} }],
      },
    ],
    direct: [
      {
        id: "games_played",
        title: "Word Duels",
        subtitle: "Your wordsmith battles together",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "longest_words_played",
        title: "Epic Discoveries",
        subtitle: "The most impressive finds in your duels",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "pangrams_found",
        title: "Perfect Words",
        subtitle: "Complete alphabet masterpieces you both found",
        halfWidth: true,
        children: [{ component: TagList, props: {} }],
      },
      {
        id: "words_found",
        title: "Word Hunter",
        subtitle: "Who spots more words?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "games_won",
        title: "Victory Count",
        subtitle: "Who takes home more wins?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "average_word_score",
        title: "Word Power",
        subtitle: "Who finds the mightier words?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "average_words_found",
        title: "Word Discovery",
        subtitle: "The ultimate word hunter showdown",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
    ],
  },

  [PICTURE_PUZZLE]: {
    workspace: [
      {
        id: "picture_puzzle_performance",
        title: "Picture Perfect",
        subtitle: "Your eye for detail in numbers",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "picture_puzzle_guess_distribution",
        title: "Guessing Game",
        subtitle: "Your journey to the right answer",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
    ],
    channel: [
      {
        id: "game_counts",
        title: "Puzzle Progress",
        subtitle: "Visual victories in this channel",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "guess_distribution",
        title: "Sixth Sense",
        subtitle: "How quickly the channel cracks the code",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "top_players_in_channel",
        title: "Eagle Eyes",
        subtitle: "The channel's visual virtuosos",
        halfWidth: false,
        children: [{ component: Testimonials, props: {} }],
      },
    ],
    direct: [
      {
        id: "games_played",
        title: "Picture Partners",
        subtitle: "Your shared puzzle adventures",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "average_number_of_guesses",
        title: "Detective Work",
        subtitle: "Who needs fewer clues?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "games_solved",
        title: "Puzzle Master",
        subtitle: "Your mystery-solving success rate",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "games_won",
        title: "First to Finish",
        subtitle: "Who solves it faster?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
    ],
  },

  [TYPING_TEST]: {
    workspace: [
      {
        id: "typing_test_performance",
        title: "Typing Report Card",
        subtitle: "Your keyboard skills decoded",
        halfWidth: false,
        children: [{ component: SquaresGrid, props: { maxColumns: 4 } }],
      },
      {
        id: "typing_test_wpm_trend",
        title: "Speed Journey",
        subtitle: "Watch your typing velocity evolve",
        halfWidth: true,
        children: [{ component: GradientLineChart, props: {} }],
      },
      {
        id: "typing_test_accuracy_trend",
        title: "Precision Path",
        subtitle: "Your journey to typing perfection",
        halfWidth: true,
        children: [{ component: GradientLineChart, props: {} }],
      },
    ],
    channel: [
      {
        id: "game_counts",
        title: "Typing Trials",
        subtitle: "Speed tests in this channel",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "overall_performance",
        title: "Speed Report",
        subtitle: "Your typing prowess here",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "fastest_players_in_channel",
        title: "Speed Demons",
        subtitle: "The fastest fingers in the channel",
        halfWidth: true,
        children: [{ component: TestimonialsVertical, props: {} }],
      },
      {
        id: "most_accurate_players_in_channel",
        title: "Precision Kings",
        subtitle: "Masters of accuracy",
        halfWidth: true,
        children: [{ component: TestimonialsVertical, props: {} }],
      },
    ],
    direct: [
      {
        id: "games_played",
        title: "Speed Sessions",
        subtitle: "Your typing duels together",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "average_words_per_minute",
        title: "Speed Battle",
        subtitle: "Who has the fastest fingers?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "average_accuracy",
        title: "Accuracy Arena",
        subtitle: "Who types with more precision?",
        halfWidth: true,
        children: [{ component: HorizontalBar, props: {} }],
      },
      {
        id: "words_per_minute_over_time",
        title: "Speed Race",
        subtitle: "The ultimate typing showdown",
        halfWidth: true,
        children: [{ component: GradientLineChartComparison, props: {} }],
      },
      {
        id: "accuracy_over_time",
        title: "Precision Battle",
        subtitle: "Who's getting more accurate?",
        halfWidth: true,
        children: [{ component: GradientLineChartComparison, props: {} }],
      },
    ],
  },

  [TRIVIA]: {
    workspace: [
      {
        id: "trivia_performance",
        title: "Knowledge Bank",
        subtitle: "Your trivia mastery revealed",
        halfWidth: false,
        children: [{ component: SquaresGrid, props: { maxColumns: 3 } }],
      },
      {
        id: "trivia_category_performance",
        title: "Expert Topics",
        subtitle: "Where your knowledge shines brightest",
        halfWidth: false,
        children: [{ component: RadarChartComponent, props: {} }],
      },
    ],
    channel: [
      {
        id: "game_counts",
        title: "Quiz Quest",
        subtitle: "Your trivia adventures here",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "top_players_in_channel",
        title: "Knowledge Champions",
        subtitle: "The channel's trivia titans",
        halfWidth: false,
        children: [{ component: Testimonials, props: {} }],
      },
    ],
    direct: [
      {
        id: "games_played",
        title: "Brain Battle",
        subtitle: "Your trivia showdowns together",
        halfWidth: true,
        children: [{ component: SquaresGrid, props: {} }],
      },
      {
        id: "category_performance",
        title: "Domain Dominance",
        subtitle: "Who rules each knowledge realm?",
        halfWidth: true,
        children: [{ component: ComparativeBarChart, props: {} }],
      },
    ],
  },
};

const GameInsight: React.FC<GameInsightProps> = ({
  data,
  game,
  comparisonType = "workspace",
}) => {
  const config = gameInsightConfigs[game.name]?.[comparisonType];
  if (!config || !data.insights) return null;

  const renderInsightContent = (widget: InsightConfig) => {
    const insights = data.insights as GameInsightsMap[keyof GameInsightsMap];

    if (!insights) return null;

    return widget.children.map((child, idx: number) => {
      const ChildComponent = child.component;
      const insightKey = widget.id as keyof typeof insights;
      const insightData = insights[insightKey];

      if (!insightData) {
        return (
          <div
            className="h-[180px] flex items-center justify-center text-sm text-gray-500"
            key={`${idx}-not-enough-data`}
          >
            📉 No data available
          </div>
        );
      }

      const hasEnoughData = Array.isArray(insightData)
        ? insightData.length > 0
        : typeof insightData === "object" &&
          Object.keys(insightData).length > 0;

      if (!hasEnoughData) {
        return (
          <div
            className="h-[180px] flex items-center justify-center text-sm text-gray-500"
            key={`${idx}-not-enough-data`}
          >
            ⏳ Not enough data to show insights
          </div>
        );
      }

      return (
        <ChildComponent
          key={`${String(widget.id)}-${idx}`}
          // @ts-expect-error fine
          data={insightData}
          {...child.props}
        />
      );
    });
  };

  return (
    <div className="max-w-full w-[64rem] px-0 md:px-[18px] mx-auto grid gap-6 grid-col-1">
      {config.map((widget) => (
        <div key={widget.id} className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <WidgetCard title={widget.title} subtitle={widget.subtitle}>
            {renderInsightContent(widget)}
          </WidgetCard>
        </div>
      ))}
    </div>
  );
};

export default GameInsight;
