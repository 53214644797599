import { useUserData } from "./useUserData";
import { Subscription, SubscriptionTier } from "../types/user";

export function useUserProfile() {
  const {
    data: userData,
    isLoading,
    isError,
    error,
    refetch: refetchUserData,
  } = useUserData();

  const subscription: Subscription | undefined =
    userData?.slack_user?.subscription;

  const subscriptionTier: SubscriptionTier | undefined = subscription?.tier;

  const isPremium =
    userData?.slack_user?.subscription?.tier?.tier_type === "Premium";

  return {
    isLoading,
    isError,
    isPremium,
    error,
    userData,
    subscription,
    subscriptionTier,
    refetchUserData,
  };
}
