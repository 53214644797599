const API_BASE_URL = import.meta.env.VITE_BASE_API_URL;

const MINIMUM_PLAYS_FOR_INSIGHTS = 3;

const THIS_OR_THAT = "SLACK_THIS_OR_THAT";
const WORD_LINK = "SLACK_WORD_LINK";
const PICTURE_PUZZLE = "SLACK_PICTURE_PUZZLE";
const TYPING_TEST = "SLACK_TYPING_TEST";
const WORDSMITH = "SLACK_WORDSMITH";
const TRIVIA = "SLACK_TRIVIA";

const DEFAULT_SEAT_COUNT_UPGRADE = 20; // When upgrading set the default seat count to this number

const SEAT_PRICE_MONTH_ANNUAL = 5; // When paid annually
const SEAT_PRICE_MONTH = 7; // When paid monthly

export {
  API_BASE_URL,
  MINIMUM_PLAYS_FOR_INSIGHTS,
  THIS_OR_THAT,
  WORD_LINK,
  PICTURE_PUZZLE,
  TYPING_TEST,
  WORDSMITH,
  TRIVIA,
  SEAT_PRICE_MONTH_ANNUAL,
  SEAT_PRICE_MONTH,
  DEFAULT_SEAT_COUNT_UPGRADE,
};
