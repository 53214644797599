import { useState } from "react";

import { useUserProfile } from "../hooks/useUserProfile";
import Spinner from "../components/spinner";
import InsightCard from "../components/insightCard";
import UpgradeSection from "../components/upgradePrompt";
import { SlackUser } from "../types/user";
import { GameType } from "../types/games";
import { ComparisonType } from "../types/insights";

import ComparisonBar from "../components/comparisonBar";
import ComparisonInfoBar from "../components/comparisonInfoBar";

interface AvailableGamesProps {
  games: GameType[];
  slackUser?: SlackUser;
  isPremium: boolean;
  comparisonParams: {
    type: ComparisonType;
    id: string | null;
  };
}

interface ComparisonSelections {
  channel: string | null;
  direct: string | null;
}

interface ComparisonState {
  type: ComparisonType;
  selections: ComparisonSelections;
  isComplete: boolean;
}

const AvailableGames: React.FC<AvailableGamesProps> = ({
  games,
  slackUser,
  isPremium,
  comparisonParams,
}) => {
  if (!games.length) {
    return null;
  }

  // If premium, show all game insights
  if (isPremium) {
    return (
      <div>
        {games.map((game) => (
          <InsightCard
            key={game.id}
            game={game}
            slackUser={slackUser}
            comparisonId={comparisonParams.id}
            comparisonType={comparisonParams.type}
          />
        ))}
      </div>
    );
  }

  // If not premium, show first game followed by upgrade section
  return (
    <div>
      {/* Show first game insight */}
      <InsightCard
        game={games[0]}
        slackUser={slackUser}
        comparisonId={comparisonParams.id}
        comparisonType={comparisonParams.type}
      />

      {/* Show upgrade section if there are more games */}
      {games.length > 1 && <UpgradeSection />}
    </div>
  );
};

function Profile() {
  const { userData, isLoading, isError, error, isPremium } = useUserProfile();

  const [comparison, setComparison] = useState<ComparisonState>({
    type: "workspace",
    selections: {
      channel: null,
      direct: null,
    },
    isComplete: true,
  });

  const handleComparisonChange = (type: ComparisonType) => {
    setComparison((prev) => ({
      ...prev,
      type,
      isComplete:
        type === "workspace" ||
        Boolean(prev.selections[type === "channel" ? "channel" : "direct"]),
    }));
  };

  const handleComparisonSelect = (option: { id: string | null }) => {
    setComparison((prev) => ({
      ...prev,
      selections: {
        ...prev.selections,
        [prev.type === "channel" ? "channel" : "direct"]: option.id,
      },
      isComplete: prev.type === "workspace" || Boolean(option.id),
    }));
  };

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="font-semibold text-brandRed">
          Error: {error instanceof Error ? error.message : "An error occurred"}
        </div>
      </div>
    );
  }

  if (!userData) {
    return <div className="text-center mb-4">💥 No user data available.</div>;
  }

  const availableGames =
    userData.slack_user?.subscription.available_games || [];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="space-y-4">
        <ComparisonBar
          selected={comparison.type}
          onChange={handleComparisonChange}
          onSelect={handleComparisonSelect}
          selectedOptionId={
            comparison.selections[
              comparison.type === "channel" ? "channel" : "direct"
            ]
          }
        />

        {comparison.isComplete && (
          <ComparisonInfoBar
            comparisonType={comparison.type}
            comparisonId={
              comparison.selections[
                comparison.type === "channel" ? "channel" : "direct"
              ]
            }
            userData={userData}
          />
        )}

        <div className="flex-1 overflow-y-auto">
          {/* Scrollable content */}
          {comparison.isComplete ? (
            <AvailableGames
              games={availableGames}
              slackUser={userData.slack_user}
              isPremium={isPremium}
              comparisonParams={{
                type: comparison.type,
                id: comparison.selections[
                  comparison.type === "channel" ? "channel" : "direct"
                ],
              }}
            />
          ) : (
            <div className="bg-white rounded-lg shadow-sm p-6 mb-4">
              <div className="flex flex-col items-center justify-center text-gray-500 py-8">
                <p>
                  Select a{" "}
                  {comparison.type === "channel" ? "channel" : "teammate"} to
                  view insights
                </p>
                <p className="text-sm mt-2">
                  Use the search bar above to choose who to compare with
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
