import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { API_BASE_URL, MINIMUM_PLAYS_FOR_INSIGHTS } from "../constants";
import { SlackUser } from "../types/user";
import { ComparisonType } from "../types/insights";
import LoadingSpinner from "./spinner";
import GameInsight from "./gameInsights";
import { generateSlackDeepLink } from "../lib/utils";

import { GameName, GameType, GameInsightsMap } from "../types/games";

interface InsightCardDataResponse {
  game_type: GameName;
  play_count: number;
  insights?: GameInsightsMap[GameName];
  status: "ready" | "computing" | "insufficient_games";
}

interface ProgressStepsProps {
  total: number;
  current: number;
}

const ProgressSteps = ({ total, current }: ProgressStepsProps) => {
  return (
    <div className="flex items-center gap-2 ">
      {[...Array(total)].map((_, index) => (
        <div
          key={index}
          className={`h-2 rounded-full flex-1 transition-colors space-x-2 ${
            index < current ? "bg-brand" : "bg-brand/20"
          }`}
        />
      ))}
    </div>
  );
};

interface InsightCardProps {
  game: GameType;
  slackUser?: SlackUser;
  comparisonType?: ComparisonType;
  comparisonId?: string | null;
}

const InsightCard = ({
  game,
  slackUser,
  comparisonType,
  comparisonId,
}: InsightCardProps) => {
  const [state, setState] = useState<{
    status: "idle" | "loading" | "error" | "success";
    data?: InsightCardDataResponse;
    error?: string;
  }>({ status: "idle" });

  const [searchParams] = useSearchParams();
  const api = useApi();

  useEffect(() => {
    // Reset state on dependency changes
    setState({ status: "loading" });

    // Only fetch if we have valid comparison params
    const isValid =
      comparisonType === "workspace" || Boolean(comparisonType && comparisonId);
    if (!isValid) return;

    const controller = new AbortController();
    let mounted = true;

    async function fetchInsights() {
      try {
        const response = await api.get(
          `${API_BASE_URL}/v2/insights/games/${game.name.toLowerCase()}`,
          {
            params: {
              team: searchParams.get("team"),
              comparison_type: comparisonType,
              comparison_id: comparisonId,
            },
            signal: controller.signal,
          },
        );

        if (mounted) {
          setState({ status: "success", data: response.data });
        }
      } catch (err) {
        if (!mounted || (err instanceof Error && err.name === "AbortError"))
          return;

        Sentry.captureException(err);
        setState({
          status: "error",
          error:
            err instanceof AxiosError
              ? err.response?.status === 404
                ? "No insights found"
                : err.response?.status === 401
                  ? "Please sign in to view insights"
                  : "Failed to load insights"
              : "An unexpected error occurred",
        });
      }
    }

    fetchInsights();

    return () => {
      mounted = false;
      controller.abort();
    };
  }, [comparisonType, api, comparisonId, game.name, searchParams]);

  const renderContent = () => {
    switch (state.status) {
      case "loading":
        return (
          <div className="h-[180px] flex items-center justify-center">
            <LoadingSpinner />
          </div>
        );

      case "error":
        return (
          <div className="h-[180px] flex items-center justify-center text-sm text-brandRed">
            {state.error}
          </div>
        );

      case "success":
        if (!state.data) return null;

        switch (state.data.status) {
          case "insufficient_games":
            return (
              <div className="flex flex-col gap-3 bg-brand/10 p-4 rounded mt-4">
                <ProgressSteps
                  total={MINIMUM_PLAYS_FOR_INSIGHTS}
                  current={state.data.play_count}
                />
                <p className="text-sm text-brand">
                  Play {MINIMUM_PLAYS_FOR_INSIGHTS - state.data.play_count} more{" "}
                  {state.data.play_count === MINIMUM_PLAYS_FOR_INSIGHTS - 1
                    ? "game"
                    : "games"}{" "}
                  to unlock this insight
                </p>
              </div>
            );

          case "computing":
            return (
              <div className="h-[180px] flex flex-col items-center justify-center gap-3">
                <LoadingSpinner />
                <p className="text-sm text-gray-600">
                  🤖 Crunching your insights, check back soon ...
                </p>
              </div>
            );

          case "ready":
            return (
              <GameInsight
                data={state.data}
                game={game}
                comparisonType={comparisonType}
              />
            );
        }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-4 border border-brand/20">
      <div className="flex flex-col">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <img
              src={game.mini_image}
              alt={game.title}
              className="w-8 h-8 mr-2"
            />
            <h3 className="text-lg font-semibold">{game.title}</h3>
            <span className="text-xs text-gray-500 pl-4 pt-1">
              {game.description}
            </span>
          </div>
          <a
            href={generateSlackDeepLink(slackUser)}
            className="bg-gradient-cta text-white text-md px-4 py-2 rounded-[8px] hover:bg-gradient-cta-hover transition-colors whitespace-nowrap shadow-sm"
          >
            Play now
          </a>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default InsightCard;
