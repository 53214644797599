import { useNavigate, useSearchParams } from "react-router-dom";
import { SlackInstallation } from "../types/user";

interface WorkspaceSwitcherProps {
  installations: SlackInstallation[];
  currentTeamId: string | null;
}

const WorkspaceSwitcher = ({
  installations,
  currentTeamId,
}: WorkspaceSwitcherProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleWorkspaceChange = (teamId: string) => {
    if (teamId === currentTeamId) return;

    const redirectUrl = searchParams.get("redirect_url");
    if (redirectUrl) {
      const decodedUrl = decodeURIComponent(redirectUrl);
      const redirectSearchParams = new URLSearchParams(
        decodedUrl.split("?")[1] || "",
      );
      redirectSearchParams.set("team", teamId);

      const baseUrl = decodedUrl.split("?")[0];
      navigate(`${baseUrl}?${redirectSearchParams.toString()}`, {
        replace: true,
      });
    } else {
      navigate(`/profile?team=${teamId}`, { replace: true });
    }
  };

  return (
    <div
      className="w-full grid gap-3"
      role="navigation"
      aria-label="Workspace switcher"
    >
      {installations.map((installation) => (
        <button
          key={installation.team_id}
          onClick={() => handleWorkspaceChange(installation.team_id)}
          className={`
            group relative w-full px-6 py-4 text-center
            rounded-xl transition-all duration-200 
            hover:shadow-lg hover:-translate-y-0.5
            ${
              currentTeamId === installation.team_id
                ? "bg-gradient-to-r from-brand/90 to-brand text-white shadow-md"
                : "bg-white text-gray-700 hover:bg-gray-50/90"
            }
          `}
        >
          <span className="text-base font-medium">
            {installation.team_name}
          </span>

          {/* Optional subtle border animation on hover */}
          <div
            className={`
            absolute inset-0 rounded-xl border-2 
            transition-colors duration-200
            ${
              currentTeamId === installation.team_id
                ? "border-transparent"
                : "border-gray-100 group-hover:border-brand/20"
            }
          `}
          />
        </button>
      ))}
    </div>
  );
};

export default WorkspaceSwitcher;
