import { SlackUser, Subscription } from "../types/user";
import { PromoData } from "../types/promo";
import { SEAT_PRICE_MONTH, SEAT_PRICE_MONTH_ANNUAL } from "../constants";

const generateSlackDeepLink = (slackUser?: SlackUser) => {
  if (!slackUser) return "";

  const { app_id, team_id } = slackUser;

  return `slack://app?team=${team_id}&id=${app_id}&tab=home`;
};

// Helper functions
const isActivePremium = (subscription: Subscription | undefined) => {
  if (!subscription) {
    return false;
  }

  // Also if we're cancelling we're still considered premium until the end of the billing billing_period
  if (isCancelling(subscription)) {
    return true;
  }

  return (
    subscription?.status === "active" &&
    subscription.tier.tier_type === "Premium"
  );
};

const isCancelling = (subscription: Subscription | undefined) => {
  if (!subscription) {
    return false;
  }
  return subscription?.status === "canceling";
};

// Update status text display
const getStatusText = (subscription: Subscription | undefined) => {
  if (!subscription) {
    return "--";
  }

  const titleCaseBillingPeriod =
    subscription.billing_period.charAt(0).toUpperCase() +
    subscription.billing_period.slice(1);

  if (subscription.status === "canceling") {
    return `Cancels at period end • ${titleCaseBillingPeriod} • ${subscription.user_count} seats`;
  }
  if (isActivePremium(subscription)) {
    return `Current Plan • ${titleCaseBillingPeriod} • ${subscription.user_count} seats`;
  } else {
    return `From $${SEAT_PRICE_MONTH}/seat per month or $${SEAT_PRICE_MONTH_ANNUAL}/seat per year`;
  }
};

const calculatePrice = (
  isAnnual: boolean,
  seats: number,
  promoData: PromoData | null,
) => {
  // Calculate monthly base price first
  const monthlyBasePrice =
    seats * (isAnnual ? SEAT_PRICE_MONTH_ANNUAL : SEAT_PRICE_MONTH);

  // For annual, multiply by 12 months
  const basePrice = isAnnual ? monthlyBasePrice * 12 : monthlyBasePrice;

  // Apply discount if promo exists
  if (promoData?.discount) {
    return basePrice * ((100 - promoData.discount.percent_off) / 100);
  }

  return basePrice;
};

const formatPromoMessage = (promo: PromoData) => {
  const expiresAt = new Date(promo.expires_at);
  const discount = promo.discount;

  let message = `${discount.percent_off}% off`;

  if (discount.duration === "repeating" && discount.duration_months) {
    message += ` for ${discount.duration_months} month${discount.duration_months > 1 ? "s" : ""}`;
  } else if (discount.duration === "forever") {
    message += " forever";
  } else if (discount.duration === "once") {
    message += " for first month";
  }

  if (expiresAt) {
    message += ` (expires ${expiresAt.toLocaleDateString()})`;
  }

  return message;
};

const calculateAnnualSavings = (
  monthlyPrice: number,
  annualPrice: number,
): string => {
  const effectiveMonthlyPrice = annualPrice / 12;
  const savings = ((monthlyPrice - effectiveMonthlyPrice) / monthlyPrice) * 100;

  // Round to the nearest 5 for better UX
  const roundedSavings = Math.round(savings / 5) * 5;

  return `Save ~${roundedSavings}%`;
};

export {
  generateSlackDeepLink,
  isActivePremium,
  isCancelling,
  getStatusText,
  calculatePrice,
  formatPromoMessage,
  calculateAnnualSavings,
};
