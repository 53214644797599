export const COMPARISON_TYPES = {
  workspace: "You",
  channel: "Channel",
  direct: "1-on-1",
};

export type ComparisonType = keyof typeof COMPARISON_TYPES;

export interface ComparisonParams {
  type: ComparisonType;
  teamId: string;
  channelId?: string;
  comparisonUserId?: string;
}
