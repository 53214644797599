import { Search } from "lucide-react";
import { useUserData } from "../hooks/useUserData";
import Card from "./card";
import { useState, useEffect } from "react";

import { COMPARISON_TYPES, ComparisonType } from "../types/insights";

interface SearchOption {
  id: string;
  name: string;
  subtitle?: string;
  avatar_url?: string;
}

const SearchBar = ({
  placeholder,
  value,
  onChange,
  options,
  onSelect,
  selectedId,
}: {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  options: SearchOption[];
  onSelect: (option: SearchOption) => void;
  selectedId?: string | null;
}) => (
  <div className="flex items-center gap-4">
    <div className="relative w-64 flex-shrink-0">
      <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="block w-full pl-8 pr-3 py-1 border border-gray-300 bg-gray-200 rounded-[20px] 
                  focus:bg-white focus:ring-1 focus:ring-brand focus:border-brand text-sm"
        placeholder={placeholder}
      />
    </div>

    <div className="flex gap-2 overflow-x-auto">
      {options.length === 0 ? (
        <span className="text-gray-500 text-sm">
          {value ? 'No results found for "' + value + '"' : "No results yet"}
        </span>
      ) : (
        options.map((option) => {
          const isSelected = selectedId && option.id === selectedId;

          return (
            <button
              key={option.id}
              onClick={() => onSelect(option)}
              className={`
                flex-shrink-0 px-2 py-1 rounded-[20px] transition-colors
                ${
                  isSelected
                    ? "bg-brand text-white font-bold"
                    : "bg-brand/10 text-brand hover:bg-brand/50"
                }
              `}
            >
              {option.avatar_url ? (
                <div className="flex items-center gap-2">
                  <img
                    src={option.avatar_url}
                    className="w-4 h-4 rounded-full border"
                  />{" "}
                  {option.name}
                </div>
              ) : (
                <span className="font-medium text-sm whitespace-nowrap">
                  {option.name}
                </span>
              )}
            </button>
          );
        })
      )}
    </div>
  </div>
);

interface ComparisonBarProps {
  selected: ComparisonType;
  onChange: (type: ComparisonType) => void;
  onSelect: (option: { id: string | null }) => void;
  selectedOptionId?: string | null;
}

const ComparisonBar = ({
  selected = "workspace",
  onChange,
  onSelect,
  selectedOptionId: externalSelectedId,
}: ComparisonBarProps) => {
  const { data: userData, isError } = useUserData();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);

  // Update internal selected state when external changes
  useEffect(() => {
    setSelectedOptionId(externalSelectedId || null);
  }, [externalSelectedId]);

  // Clear search when comparison type changes
  useEffect(() => {
    setSearchQuery("");
  }, [selected]);

  if (isError) {
    return <Card>Error loading comparison data</Card>;
  }

  // Ensure unique entries by using id as key
  const channels = userData?.played_channels.reduce((acc, c) => {
    if (!acc.some((existing) => existing.id === c.id)) {
      acc.push({
        id: c.id,
        name: `#${c.name}`,
      });
    }
    return acc;
  }, [] as SearchOption[]);

  const teammates = userData?.played_teammates?.reduce((acc, t) => {
    if (!acc.some((existing) => existing.id === t.id)) {
      acc.push(t);
    }
    return acc;
  }, [] as SearchOption[]);

  const searchOptions =
    selected === "workspace"
      ? []
      : selected === "channel"
        ? channels || []
        : teammates || [];

  const filteredOptions = searchQuery
    ? searchOptions.filter((opt) =>
        opt.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : searchOptions;

  const handleSelect = (option: SearchOption) => {
    const newSelectedId = option.id === selectedOptionId ? null : option.id;
    setSelectedOptionId(newSelectedId);
    onSelect({ id: newSelectedId });
  };

  return (
    <div className="bg-white rounded-[12px] shadow-md shadow-brand/10 overflow-hidden mb-8 border border-brand/30">
      <div className="flex w-full divide-x divide-gray-200">
        {Object.entries(COMPARISON_TYPES).map(([type, label]) => (
          <button
            key={type}
            onClick={() => onChange(type as ComparisonType)}
            className={`
              flex-1 py-5 text-md font-medium transition-colors
              ${
                selected === type
                  ? "bg-brand text-white"
                  : "text-gray-500 hover:text-gray-900"
              }
            `}
          >
            {label}
          </button>
        ))}
      </div>
      {selected !== "workspace" && (
        <div className="p-5 border-t">
          <SearchBar
            placeholder={`Search ${selected === "channel" ? "channels" : "teammates"}...`}
            value={searchQuery}
            onChange={setSearchQuery}
            options={filteredOptions}
            onSelect={handleSelect}
            selectedId={selectedOptionId}
          />
        </div>
      )}
    </div>
  );
};

export default ComparisonBar;
