import React from "react";

export interface ComparisonOption {
  title: string;
  text?: string;
}

export interface ComparisonTagListProps {
  data: [ComparisonOption, ComparisonOption][];
}

export const ComparisonTagList: React.FC<ComparisonTagListProps> = ({
  data,
}) => {
  if (!data?.length) return null;

  return (
    <div className="flex flex-col gap-4">
      {data.map(([left, right], idx) => (
        <div key={idx} className="grid grid-cols-2 gap-4">
          <div className="flex items-center justify-end">
            <div className="py-2 px-4 font-sans text-brand leading-4 text-sm tracking-tight bg-brand/20 rounded-[32px]">
              <span className="font-bold">{left.title}</span>
              {left.text && (
                <span className="ml-2 font-normal">{left.text}</span>
              )}
            </div>
          </div>

          <div className="flex items-center">
            <div className="py-2 px-4 font-sans text-brand leading-4 text-sm tracking-tight bg-gray-100 rounded-[32px]">
              <span className="font-bold">{right.title}</span>
              {right.text && (
                <span className="ml-2 font-normal">{right.text}</span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComparisonTagList;
