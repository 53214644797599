import { useState } from "react";
import { buildApiUrl } from "../../api";
import * as axios from "axios";

import { PromoData } from "../../types/promo";

import CheckoutForm from "./checkoutForm";
import CTAButton from "../ctaButton";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faDice,
  faUsers,
  faTrophy,
  faCog,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

import Card from "../card";
import Modal from "../modal";
import PlanSelector from "./planSelector";

import { useApi } from "../../hooks/useApi";
import { useUserProfile } from "../../hooks/useUserProfile";

import { getStatusText, isActivePremium, isCancelling } from "../../lib/utils";

import { DEFAULT_SEAT_COUNT_UPGRADE } from "../../constants";

import * as Sentry from "@sentry/react";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface FeatureItemProps {
  basic?: boolean;
  icon: IconProp;
  text: string;
}

function FeatureItem({ basic, icon, text }: FeatureItemProps) {
  const bgColor = basic ? "bg-white border-2 border-gray-100" : "bg-brand/10";
  const iconColor = basic ? "text-gray-500" : "text-brand/90";

  return (
    <div
      className={`${bgColor} flex flex-col items-center py-5 rounded-[8px] flex-grow`}
    >
      <FontAwesomeIcon icon={icon} className={`${iconColor} mb-2 text-lg`} />
      <span className="text-xs text-center px-2">{text}</span>
    </div>
  );
}

interface SubscriptionSectionProps {
  teamId: string | null;
  promoCode?: string | null;
}

function SubscriptionSection({ teamId, promoCode }: SubscriptionSectionProps) {
  const [isModalOpen, setIsModalOpen] = useState(!!promoCode); // Opened automatically if the promo code is present (from url)
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const [selectedPlan, setSelectedPlan] = useState<"monthly" | "annual" | null>(
    null,
  );

  const [step, setStep] = useState<"plan" | "checkout">("plan");
  const [selectedSeats, setSelectedSeats] = useState<number>(0);

  const [isPlanSelectionLoading, setIsPlanSelectionLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [promoData, setPromoData] = useState<PromoData | null>(null);

  const api = useApi();
  const {
    subscription,
    isLoading: isSubscriptionLoading,
    isError,
    refetchUserData,
  } = useUserProfile();

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setStep("plan");
    setError(null);

    // Auto-apply promo if present
    if (promoCode) {
      // Clean URL after reading promo
      const newUrl = teamId
        ? `${window.location.pathname}?team=${teamId}`
        : window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setClientSecret(null);
    setSelectedPlan(null);
    setSelectedSeats(0);
    setStep("plan");
    setError(null);
  };

  const handleBackToPlan = () => {
    setStep("plan");
    setClientSecret(null);
    setError(null);
  };

  const handlePlanSelect = async (
    plan: "monthly" | "annual",
    seats: number,
    promoData: PromoData | null,
  ) => {
    setSelectedPlan(plan);
    setSelectedSeats(seats);
    setPromoData(promoData || null);
    setIsPlanSelectionLoading(true);
    setError(null);

    try {
      const payload = {
        plan,
        num_seats: seats,
        promo_id: promoData?.id || null,
      };

      const response = await api.post(
        buildApiUrl("/v2/stripe/setup-subscription", teamId),
        payload,
      );

      if (!response.data?.client_secret) {
        throw new Error("No client secret received from server");
      }

      setClientSecret(response.data.client_secret);
      setStep("checkout");
    } catch (error) {
      Sentry.captureException(error);

      let detail = "";
      if (axios.isAxiosError(error)) {
        detail = error.response?.data?.detail;
        setError(
          `Unable to set up payment, support has been notified${detail ? `: ${detail}` : ""}`,
        );
      } else {
        setError("Unable to set up payment, support has been notified");
      }

      setStep("plan");
    } finally {
      setIsPlanSelectionLoading(false);
    }
  };

  const handleCancel = async () => {
    if (!confirm("Are you sure you want to cancel your subscription?")) {
      return;
    }

    try {
      setLoading(true);
      await api.post("/v2/stripe/cancel-subscription", {
        subscription_id: subscription?.stripe_subscription_id,
      });
      await refetchUserData();
    } catch (error) {
      Sentry.captureException(error);
      setError("Failed to cancel subscription, support has been notified");
    } finally {
      setLoading(false);
    }
  };

  const handleReactivate = async () => {
    if (!confirm("Are you sure you want to reactivate your subscription?")) {
      return;
    }

    try {
      setLoading(true);
      await api.post("/v2/stripe/reactivate-subscription", {
        subscription_id: subscription?.stripe_subscription_id,
      });
      await refetchUserData();
    } catch (error) {
      Sentry.captureException(error);
      setError("Failed to reactivate subscription, support has been notified");
    } finally {
      setLoading(false);
    }
  };

  if (isSubscriptionLoading) {
    return <Card>Loading subscription information...</Card>;
  }

  if (isError) {
    return (
      <Card>
        Error loading subscription information. Please try again later.
      </Card>
    );
  }

  const freeFeatures = [
    { icon: faDice, text: "Library of 4 basic games" },
    { icon: faUsers, text: "Max 10 teammates" },
    { icon: faTrophy, text: "Basic insights and leaderboards" },
  ];

  const premiumFeatures = [
    { icon: faDice, text: "Library of 5+ games" },
    { icon: faTrophy, text: "Individual and team stats" },
    { icon: faCog, text: "Advanced game settings" },
    { icon: faCalendar, text: "Schedule recurring events" },
  ];

  return (
    <Card>
      <h2 className="text-xl font-semibold mb-4">Subscription</h2>

      {!isActivePremium(subscription) && (
        <div className="mb-8">
          <div className="text-lg mb-4 flex items-center justify-between">
            <h3 className="font-bold">Free</h3>
            <span className="text-sm text-gray-500 justify-self-end">
              Current Plan
            </span>
          </div>

          <div className="flex gap-2">
            {freeFeatures.map((feature, index) => (
              <FeatureItem
                key={index}
                icon={feature.icon}
                text={feature.text}
                basic
              />
            ))}
          </div>
        </div>
      )}

      <div>
        <div className="text-lg mb-4 flex items-center justify-between">
          <h3 className="font-bold">Premium</h3>
          <span className="text-sm text-gray-500 justify-self-end">
            {getStatusText(subscription)}
          </span>
        </div>

        <div className="flex gap-2">
          {premiumFeatures.map((feature, index) => (
            <FeatureItem key={index} icon={feature.icon} text={feature.text} />
          ))}
        </div>

        {isActivePremium(subscription) && (
          <div className="mt-4">
            {isCancelling(subscription) ? (
              <div>
                <p className="text-sm text-brandRed">
                  Your subscription will be cancelled at the end of the current
                  billing period.
                </p>

                <a
                  onClick={handleReactivate}
                  className={`text-brand text-sm mt-4 inline-block font-bold cursor-pointer ${
                    loading ? "opacity-50 pointer-events-none" : ""
                  }`}
                >
                  {loading ? "Reactivating..." : "Reactivate Subscription"}
                </a>
              </div>
            ) : (
              <a
                onClick={handleCancel}
                className={`text-brandRed text-sm inline-block font-bold cursor-pointer hover:text-brandRed/80 ${
                  loading ? "opacity-50 pointer-events-none" : ""
                }`}
              >
                {loading ? "Cancelling..." : "Cancel Subscription"}
              </a>
            )}
          </div>
        )}

        {!isActivePremium(subscription) && (
          <div className="mt-4">
            <CTAButton onClick={handleOpenModal} fullWidth size="lg">
              Upgrade to Premium
            </CTAButton>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        size={step === "checkout" ? "large" : "default"}
      >
        {step === "plan" ? (
          <>
            <PlanSelector
              onSelect={handlePlanSelect}
              currentSeats={DEFAULT_SEAT_COUNT_UPGRADE}
              isLoading={isPlanSelectionLoading}
              teamId={teamId}
              promoCode={promoCode}
            />
            {error && (
              <div className="px-6 pb-4">
                <div className="text-brandRed text-sm bg-red-50 p-3 rounded-lg">
                  {error}
                </div>
              </div>
            )}
          </>
        ) : clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm
              onBack={handleBackToPlan}
              plan={selectedPlan!}
              teamId={teamId}
              seats={selectedSeats}
              promoData={promoData}
            />
          </Elements>
        ) : (
          <div className="p-6 text-center">
            <div
              className="animate-spin inline-block w-6 h-6 border-2 border-current border-t-transparent text-brand rounded-full"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
            <p className="mt-2 text-gray-600">Setting up payment...</p>
          </div>
        )}
      </Modal>
    </Card>
  );
}

export default SubscriptionSection;
