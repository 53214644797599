import axios from "axios";

import { API_BASE_URL } from "./constants";

import * as Sentry from "@sentry/react";

export const createApi = (getToken: () => Promise<string | null>) => {
  const api = axios.create({
    baseURL: API_BASE_URL,
  });

  api.interceptors.request.use(async (config) => {
    const token = await getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      Sentry.withScope((scope) => {
        scope.setExtra("requestUrl", error.config.url);
        scope.setExtra("requestData", error.config.data);
        scope.setExtra("responseData", error.response?.data);
        Sentry.captureException(error);
      });
      return Promise.reject(error);
    },
  );

  return api;
};

export const buildApiUrl = (endpoint: string, teamId: string | null) => {
  const params = new URLSearchParams();
  if (teamId) params.append("team", teamId);

  return `${endpoint}${params.toString() ? `?${params.toString()}` : ""}`;
};
