import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { type GradientLineChartDataComparison } from "../../../types/games";
import React from "react";

export type GradientLineChartComparisonProps = {
  data: GradientLineChartDataComparison;
};

export const GradientLineChartComparison: React.FC<
  GradientLineChartComparisonProps
> = ({ data }) => {
  const { type: dataType, source: sourceValues, other: otherValues } = data;

  const allValues = [...sourceValues, ...otherValues].map((d) => d.value);
  const min = Math.min(...allValues);
  const max = Math.max(...allValues);
  const diff = max - min;
  const step = Math.ceil(diff / 4 / 5) * 5;
  const minTick =
    min % step < 3 ? min - (min % step) : min + (step - (min % step));
  const maxTick =
    max % step >= 3 ? max + (step - (max % step)) : max - (max % step);
  const ticks = new Array((maxTick - minTick) / step + 1)
    .fill(1)
    .map((_, index) => minTick + index * step);

  // Combine data for chart
  const chartData = sourceValues.map((item, index) => ({
    name:
      index === 0
        ? "First Game"
        : index === sourceValues.length - 1
          ? "Last Game"
          : "",
    you: item.value,
    other: otherValues[index]?.value,
  }));

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: -15,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          fill="#808080"
          fontSize={12}
          fontFamily="Inter"
        />
        <YAxis
          type="number"
          tickLine={false}
          axisLine={false}
          allowDataOverflow
          padding={{ bottom: 30, top: 20 }}
          domain={[minTick, maxTick]}
          ticks={ticks}
          tick={({ y, payload }) => (
            <g transform={`translate(${0},${y + 2})`}>
              <text
                x={0}
                y={2}
                fill="#808080"
                textAnchor="start"
                fontSize={12}
                fontFamily="Inter"
              >
                {payload.value}
                {dataType === "percent" && "%"}
              </text>
            </g>
          )}
        />
        <defs>
          <linearGradient id="sourceGradient" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#7367FF" />
            <stop offset="100%" stopColor="#4641EB" />
          </linearGradient>
          <linearGradient id="otherGradient" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#dddddd" />
            <stop offset="100%" stopColor="#cccccc" />
          </linearGradient>
        </defs>
        <Line
          type="monotone"
          dataKey="you"
          stroke="url(#sourceGradient)"
          strokeWidth={4}
          strokeLinecap="round"
          dot={false}
          name="You"
        />
        <Line
          type="monotone"
          dataKey="other"
          stroke="url(#otherGradient)"
          strokeWidth={4}
          strokeLinecap="round"
          dot={false}
          name="Them"
        />
        <Legend verticalAlign="top" height={36} />
      </LineChart>
    </ResponsiveContainer>
  );
};
