import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";

import LoadingSpinner from "./spinner";

import { API_BASE_URL } from "../constants";

import { ComparisonType } from "../types/insights";
import { UserData, Teammate } from "../types/user";
import { LoadingState, LoadingStateType } from "../types/misc";

import playedTeammatesIcon from "../assets/icons/playedTeammatesIcon.png";
import playedGamesIcon from "../assets/icons/playedGamesIcon.png";

import { useApi } from "../hooks/useApi";

import Card from "./card";

interface ComparisonInfoProps {
  comparisonType: ComparisonType;
  comparisonId: string | null;
  userData: UserData;
}

interface StatsMetadata {
  name: string;
  avatar?: string;
}

interface StatsDataResponse {
  avatar?: string;
  total_play_count: number;
  last_played: string | null;
  game_icons: string[];
  teammates?: Teammate[];
  metadata: StatsMetadata;
}

function generateRandomAvatar(name: string) {
  const replaced_name = name.replace(" ", "+");
  return `https://ui-avatars.com/api/?name=${replaced_name}&background=random`;
}

const ComparisonInfoBar = ({
  comparisonType,
  comparisonId,
  userData,
}: ComparisonInfoProps) => {
  const [data, setData] = useState<StatsDataResponse | null>(null);
  const [error, setError] = useState<string>("");
  const [loadingState, setLoadingState] = useState<LoadingStateType>(
    LoadingState.INITIAL,
  );
  const [searchParams] = useSearchParams();

  const api = useApi();

  useEffect(() => {
    const isValid =
      comparisonType === "workspace" || Boolean(comparisonType && comparisonId);

    if (!isValid) return;

    const fetchComparisonStats = async () => {
      try {
        setLoadingState(LoadingState.LOADING);
        const params = {
          team: searchParams.get("team"),
          comparison_type: comparisonType,
          comparison_id: comparisonId,
        };

        const response = await api.get(`${API_BASE_URL}/v2/insights/stats`, {
          params,
        });
        setData(response.data);
        setLoadingState(LoadingState.SUCCESS);
      } catch (err) {
        Sentry.captureException(err);
        setLoadingState(LoadingState.ERROR);

        if (err instanceof AxiosError) {
          setError(
            err.response?.status === 404
              ? "No insights found"
              : err.response?.status === 401
                ? "Please sign in to view insights"
                : err.response?.data?.message || "Failed to load insights",
          );
        } else {
          setError("Something went wrong");
        }
      }
    };

    fetchComparisonStats();
  }, [api, comparisonType, comparisonId, searchParams]);

  if (
    loadingState === LoadingState.INITIAL ||
    loadingState === LoadingState.LOADING
  ) {
    return (
      <div className="h-[180px] flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (loadingState === LoadingState.ERROR) {
    return (
      <Card>
        <div className="h-[180px] flex items-center justify-center text-sm text-brandRed">
          📈 Could not load comparison stats
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <div className="flex items-center justify-center text-sm text-brandRed">
          Error fetching comparison stats
        </div>
      </Card>
    );
  }

  if (!data) {
    return <Card>No data</Card>;
  }

  const teammateIcons =
    data.teammates?.map((t) => t.avatar_url || generateRandomAvatar(t.name)) ||
    [];

  if (comparisonType === "workspace") {
    return (
      <div className="bg-white rounded-[12px] shadow-xl shadow-brand/30 overflow-hidden mb-8 px-2 md:px-[20px] py-[20px] border border-brand/20">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              className="h-16 w-16 rounded-full mr-4"
              src={userData.slack_user?.avatar_url}
              alt={userData.slack_user?.name || "User avatar"}
            />
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {userData.slack_user?.name}
              </h3>
              <div className="text-[12px] text-gray-500">
                {userData.slack_user?.email}
              </div>
              <div className="text-[12px] text-gray-500">
                Team &mdash; {userData.slack_user?.installation?.team_name}
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-2 md:space-x-6">
            <StatItem
              icons={data.game_icons}
              defaultIcon={playedGamesIcon}
              prefix="Played"
              suffix="games"
              value={data.total_play_count}
            />
            <StatItem
              icons={teammateIcons}
              defaultIcon={playedTeammatesIcon}
              prefix="With"
              suffix="teammates"
              value={data.teammates?.length || 0}
              isAvatar
            />
          </div>
        </div>
      </div>
    );
  }

  if (comparisonType === "channel" && comparisonId) {
    return (
      <div className="bg-white rounded-[12px] shadow-xl shadow-brand/30 overflow-hidden mb-8 px-2 md:px-[20px] py-[20px] border border-brand/20">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium">#{data.metadata.name}</h3>
          <div className="flex gap-6">
            <StatItem
              defaultIcon={playedGamesIcon}
              prefix="Played"
              suffix="games"
              value={data.total_play_count}
              icons={data.game_icons}
            />
            <StatItem
              defaultIcon={playedTeammatesIcon}
              prefix=""
              suffix="teammates"
              value={data.teammates?.length || 0}
              icons={teammateIcons}
              isAvatar
            />
          </div>
        </div>
      </div>
    );
  }

  if (comparisonType === "direct" && comparisonId) {
    return (
      <div className="bg-white rounded-[12px] shadow-xl shadow-brand/30 overflow-hidden mb-8 px-2 md:px-[20px] py-[20px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              className="h-16 w-16 rounded-full mr-4"
              src={userData.slack_user?.avatar_url}
              alt={userData.slack_user?.name || "User avatar"}
            />
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {userData.slack_user?.name}
              </h3>
            </div>
          </div>

          <span className="text-lg text-gray-400">vs</span>
          <div className="flex items-center">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900 pr-5">
                {data.metadata.name || "--"}
              </h3>
            </div>

            <img
              className="h-16 w-16 rounded-full mr-4"
              src={data.metadata.avatar || generateRandomAvatar("NA")}
              alt={data.metadata.name || "User avatar"}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

interface StatItemProps {
  defaultIcon: string;
  icons?: string[];
  prefix?: string;
  suffix?: string;
  value: number;
  isAvatar?: boolean;
}

const StatItem: React.FC<StatItemProps> = ({
  defaultIcon,
  icons,
  prefix,
  suffix,
  value,
  isAvatar,
}) => {
  if (!icons?.length) {
    return (
      <div className="flex flex-col items-center overflow-hidden">
        <img src={defaultIcon} className="w-8 h-8" />
        <p className="text-xs md:text-sm text-gray-500 mt-2">
          {prefix} {value > 1000 ? "999+" : value} {suffix}
        </p>
      </div>
    );
  }

  const MAX_ICONS = 5;
  const displayIcons = icons.slice(0, MAX_ICONS);
  const remainingCount = icons.length - MAX_ICONS;

  return (
    <div className="flex flex-col items-center overflow-hidden">
      <div className="flex items-center -space-x-3">
        {displayIcons.map((iconSrc, index) => (
          <div
            key={index}
            className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center border-2 border-white"
          >
            <img
              src={iconSrc}
              className={isAvatar ? "rounded-full" : "w-5 h-5"}
              alt=""
            />
          </div>
        ))}
        {remainingCount > 0 && (
          <div className="w-8 h-8 rounded-full bg-brand text-white flex items-center justify-center border-2 border-white text-xs font-medium">
            +{remainingCount}
          </div>
        )}
      </div>
      <p className="text-xs md:text-sm text-gray-500 mt-2">
        {prefix} {value > 1000 ? "999+" : value} {suffix}
      </p>
    </div>
  );
};

export default ComparisonInfoBar;
