import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useUserProfile } from "../hooks/useUserProfile";
import { useSearchParams } from "react-router-dom";
import { buildApiUrl } from "../api";
import Card from "../components/card";

import SubscriptionSection from "../components/settings/subscriptionSection";
import SupportSection from "../components/settings/supportSection";
import AccountSection from "../components/settings/accountMgmt";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

function StripeWrapper({ children }: { children: React.ReactNode }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

interface SubscriptionStatus {
  isProcessing: boolean;
  error: string | null;
  isComplete: boolean;
}

function StripeHandler() {
  const stripe = useStripe();
  const api = useApi();
  const { refetchUserData } = useUserProfile();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");
  const [status, setStatus] = useState<SubscriptionStatus>({
    isProcessing: false,
    error: null,
    isComplete: false,
  });

  useEffect(() => {
    const setupIntentSecret = searchParams.get("setup_intent_client_secret");
    const setupIntentId = searchParams.get("setup_intent");

    if (!stripe || status.isProcessing || status.isComplete) return;
    if (!setupIntentSecret || !setupIntentId) return;

    const processSetupIntent = async () => {
      setStatus((prev) => ({ ...prev, isProcessing: true }));

      try {
        // Clean URL immediately
        const newUrl = teamId
          ? `${window.location.pathname}?team=${teamId}`
          : window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);

        const { setupIntent } =
          await stripe.retrieveSetupIntent(setupIntentSecret);

        if (setupIntent?.status === "succeeded") {
          const response = await api.post(
            buildApiUrl("/v2/stripe/finalize-subscription", teamId),
            { setup_intent_id: setupIntentId },
          );

          if (response.data.status === "active") {
            await refetchUserData();
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        setStatus((prev) => ({
          ...prev,
          error: "Failed to process subscription. Please contact support.",
        }));
      } finally {
        setStatus((prev) => ({
          ...prev,
          isProcessing: false,
          isComplete: true,
        }));
      }
    };

    processSetupIntent();
  }, [
    stripe,
    teamId,
    status.isProcessing,
    status.isComplete,
    api,
    refetchUserData,
    searchParams,
  ]);

  if (status.error) {
    return (
      <Card>
        <div className="flex flex-col items-center justify-center">
          <div className="text-brandRed text-md font-medium">
            😲 Something went wrong. Support has been notified.
          </div>
        </div>
      </Card>
    );
  }

  if (status.isProcessing) {
    return (
      <Card>
        <div className="flex flex-col items-center justify-center">
          <div className="animate-spin w-6 h-6 border-2 border-brand border-t-transparent rounded-full" />
          <p className="mt-2 text-gray-600">Processing your subscription...</p>
        </div>
      </Card>
    );
  }

  return null;
}

function Settings() {
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");
  const promoCode = searchParams.get("promo"); // Extract promo code

  return (
    <StripeWrapper>
      <StripeHandler />
      <div className="container mx-auto">
        <div className="space-y-6">
          <SubscriptionSection teamId={teamId} promoCode={promoCode} />
          <SupportSection />
          <AccountSection teamId={teamId} />
        </div>
      </div>
    </StripeWrapper>
  );
}

export default Settings;
